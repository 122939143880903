import React, {useCallback, useEffect, useState} from 'react';
import DataGrid, { Column, Editing, Form } from 'devextreme-react/data-grid';
import {
    SimpleItem,
    GroupItem,
    RangeRule
} from 'devextreme-react/form';

function ScheduleGroups(props) {

    const styles = {
        title: {
            paddingTop: 10,
            paddingBottom: 0,
            paddingLeft: 20,
            marginBottom: 0,
            fontWeight: "bold",
        },
    }
    const [scheduleGroups, setScheduleGroups] = useState([{}]);


    const onRowInserted = useCallback((e) => {
        var urlString = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=sec04"
        urlString += "&ScheduleGroupName=" + e.data.ScheduleGroupName + "&ScheduleGroupOnMinutes=" + e.data.ScheduleGroupOnMinutes + "&ScheduleGroupOffMinutes=" + e.data.ScheduleGroupOFFMinutes + "&ScheduleGroupConsiderAmbientTemp=" + e.data.ScheduleGroupConsiderAmbientTemp + "&ScheduleGroupOnlyRunBelowAmbientTemp=" + e.data.ScheduleGroupOnlyRunBelowAmbientTemp + "&Enabled=" + e.data.Enabled + "&AuthorID=" + 3 + "&CompanyID=5502";
        fetch(urlString)
            .then(response => response.json())
            .then(data => {
                console.log(data);
            });
    }, []);

    const onRowUpdated = useCallback((e) => {
        var urlString = "https://phpfrontendsql.azurewebsites.net/edsInHand_v2.php?c=sec05"
        urlString += "&ScheduleGroupID=" + e.data.ScheduleGroupID + "&ScheduleGroupName=" + e.data.ScheduleGroupName + "&ScheduleGroupOnMinutes=" + e.data.ScheduleGroupOnMinutes + "&ScheduleGroupOffMinutes=" + e.data.ScheduleGroupOFFMinutes + "&ScheduleGroupConsiderAmbientTemp=" + e.data.ScheduleGroupConsiderAmbientTemp + "&ScheduleGroupOnlyRunBelowAmbientTemp=" + e.data.ScheduleGroupOnlyRunBelowAmbientTemp + "&Enabled=" + e.data.Enabled + "&EditedByID=" + 1;
        fetch(urlString)
            .then(response => response.json())
            .then(data => {
                console.log(data);
            });
    }, []);

    useEffect(() => {
        // Get SCHEDULE GROUPS
        if (props.companyID === 0) {
            return
        }
        fetch(`https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=s03&CompanyID=${props.companyID}`)
            .then(response => response.json())
            .then(data => {
                setScheduleGroups(data);
            });
    }, [props.companyID])

    return (
        <div>
            <div style={styles.title}>
                <h2>Schedule Groups</h2>
            </div>
            <DataGrid
                dataSource={scheduleGroups}
                showColumnLines={false} 
                showRowLines={true}
                onRowInserted={onRowInserted}
                onRowUpdated={onRowUpdated}
                wordWrapEnabled={true}
            >
                <Editing
                    allowUpdating={true}
                    allowAdding={true}
                    mode="form" >
                    <Form>
                        <SimpleItem dataField="ScheduleGroupName" isRequired={true}/>
                        <SimpleItem dataField="Enabled"/>
                        <GroupItem caption="On/Off Time Cycles">
                            <SimpleItem dataField="ScheduleGroupOnMinutes" isRequired={true} editorType="dxNumberBox"/>
                            <SimpleItem dataField="ScheduleGroupOFFMinutes" isRequired={true} editorType="dxNumberBox"/>
                        </GroupItem>
                        <GroupItem caption="Temperature Consideration">
                            <SimpleItem dataField="ScheduleGroupConsiderAmbientTemp" editorType="dxCheckBox" />
                            <SimpleItem dataField="ScheduleGroupOnlyRunBelowAmbientTemp" >
                                <RangeRule min={-100} max={25} />
                            </SimpleItem>
                        </GroupItem>
                    </Form>
                </Editing>
                <Column dataField="ScheduleGroupName" caption="Name" alignment="center" />
                <Column dataField="Enabled" alignment="center" dataType="boolean" />
                <Column dataField="ScheduleGroupOnMinutes" caption="ON Minutes" alignment="center" />
                <Column dataField="ScheduleGroupOFFMinutes" caption="OFF Minutes" alignment="center" />
                <Column dataField="ScheduleGroupConsiderAmbientTemp" caption="AmbientTemperatureControlsSchedule" alignment="center" dataType="boolean" />
                <Column dataField="ScheduleGroupOnlyRunBelowAmbientTemp" caption="TemperatureTrigger" alignment="center" />

            </DataGrid>

        </div>  
    )
}

export default ScheduleGroups;