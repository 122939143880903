import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DataGrid, { Scrolling } from 'devextreme-react/data-grid';
import HeaterConfig from './Components/HeaterConfig.js';
import Users from './Components/Users.js';
import ScheduleGroups from './Components/ScheduleGroups.js';
import EnCompassAdminBtn from "../Home/Components/enCompassAdminBtn";
import Navbar from "../Navbar/Navbar";

function Configuration(props) {

    const styles = {
        container: {
            paddingLeft: "5%",
            paddingRight: "5%",
        },
        titleRow: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 20,
            marginBottom: 10,
            fontWeight: "bold",
        },
        row: {
            paddingTop: 10,
            paddingBottom: 10,
            marginBottom: 10,
        },
        col: {
            paddingTop: 100,
        },
        blackMode: {
            backgroundColor: "black"
        },
        button: {
            marginRight: 5
        },
        gridHeight: {
            height: "1100px"
        }
    };



    const [peopleTypeID, setPeopleTypeID] = useState(0);
    const usersTableAccess = [1, 2, 3, 7];
    const heaterConfigTableAccess = [1, 2, 3, 4];
    const scheduleGroupsTableAccess = [1, 2, 3, 4];

    const [companies,setCompanies] = useState([{}]);
    const [companyID,UpdateCompanyId] = useState(0);
    const [isAdmin,updateAdmin] = useState(false);
    const [doNotUpdate, willNotUpdate] = useState(false);


    useEffect(() => {
        if(document.cookie === ""){
            window.location.href = "/Login";
        }
        let arrayOfCookieObjects = document.cookie.split(";")
        console.log(document.cookie);
        console.log(document.cookie.split(";"));
        if(arrayOfCookieObjects.length !== 4){ // this will eventaully be put to 4 if we put expirey date in
            window.location.href = "/Login";
        }
        let email = "";
        let personType = "";
        let companyID = "";

        for(let object of arrayOfCookieObjects){
            let indiviudalCookieInfo = object.split("=");

            if (indiviudalCookieInfo[1] === ""){ // we are checking if the values havent been put to null
               window.location.href = "/Login";
            }
            else{
                switch(indiviudalCookieInfo[0].trim()){
                    case "PeopleTypeID":

                        personType = indiviudalCookieInfo[1]
                        continue;
                    case "PersonEmail":

                        email = indiviudalCookieInfo[1]
                        continue;
                    case "CompanyID":

                        companyID = indiviudalCookieInfo[1]
                        continue;
                    default:
                        console.log(indiviudalCookieInfo[0])
                }

            }
        }

        console.log("cookie email: " + email + " peopleType: " + personType + " CompanyID: " + companyID)
        setPeopleTypeID(parseInt(personType));
        UpdateCompanyId(parseInt(companyID));
    }, [doNotUpdate]);

    const changeCompanyView = (event) =>{ // this is called by admin button
        UpdateCompanyId(event.value);
    }

    useEffect( () =>{ // useEffect runs sequenitally i think.
        // 5550 means a admin is logged in. If its not an admin we set companyID with the userLevel ie you are logged in as CPR
        if(companyID === 5500) {
            updateAdmin(true);
        }
    },[companyID])

    useEffect(() => {
        //Used to get all companies except enCompass from DB to help with switching views
        fetch('https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=c02')
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
    }, [])

    return (
        <div className="App">
            <Navbar active={2}/>
            <div className="App-body">

                <Row>{isAdmin === true && companies.length > 0 && <EnCompassAdminBtn allCompanies={companies} onClick={changeCompanyView}/> }</Row>
                {scheduleGroupsTableAccess.includes(peopleTypeID) ?
                    <Container fluid >
                        <Row bsPrefix="row align-items-start" >
                            <Col>
                                <HeaterConfig height={600} width={800} companyID={companyID}  />
                            </Col>

                        </Row>
                        {usersTableAccess.includes(peopleTypeID) ?
                            <Row bsPrefix="row align-items-start" >
                                <Col xl={6} lg={6} style={styles.col}>
                                    <Users height={600} width={800} companyID={companyID} peopleTypeID={peopleTypeID} />
                                </Col>
                                <Col xl={6} lg={6} style={styles.col}>
                                    <ScheduleGroups height={600} width={800} companyID={companyID} />
                                </Col>
                            </Row> :
                            <Row bsPrefix="row align-items-start" >

                                <Col style={styles.col}>
                                    <ScheduleGroups height={600} width={800} companyID={companyID} />
                                </Col>
                            </Row>
                        }
                    </Container> : <Container fluid ><h2> Restricted Access </h2></Container>
                }
            </div>
        </div>
    )
};

export default Configuration;