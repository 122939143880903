import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Home from './components/Home/Home';
import Configuration from './components/Configuration/Configuration';
import SignIn from "./components/Login/Login"
import PasswordChangeRequest from "./components/PasswordChange/PasswordChangeRequest";
import PasswordChange from "./components/PasswordChange/PasswordChange";

function Main(props) {

    // The root directory for React pages.
    // This takes the entire path (everything after the .com) and searches for the string at "props.rootDirectory".
    // The function then returns a string which is a substring of the entire path, minus everything to the right of "props.rootDirectory".

    const [root, setRoot] = useState(() => {
        // eslint-disable-next-line no-restricted-globals
        let n = location.pathname.toLowerCase().indexOf(props.rootDirectory.toLowerCase());
        n += props.rootDirectory.length;
        // eslint-disable-next-line no-restricted-globals
        return location.pathname.slice(0, n);
    });


    useEffect(() => {

    }, [root])


    return (
        <Switch>
            <Route exact path="/"> <Home/> </Route>
            <Route exact path="/Configuration"> <Configuration /> </Route>
            <Route exact path="/Login"> <SignIn /> </Route>
            <Route path="/PasswordChangeRequest"> <PasswordChangeRequest /> </Route>
            <Route path="/PasswordChange/:validationCode/:personID"> <PasswordChange /> </Route>
            <Route path="*"> <NotFound   /> </Route>
        </Switch>
    )
}

function NotFound(props) {
    let location = useLocation();

    return (
        <div className='App'>
            <div className='App-header'></div>
            <div className='App-body'>
                <h3> The location of <code>{location.pathname}</code> is not found. <a href={props.root}>Take me home.</a> </h3>
            </div>
        </div>
    )
}

export default Main;