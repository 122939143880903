import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'react-medium-image-zoom/dist/styles.css';
import "./SwitchDetails.css"

import 'devextreme/dist/css/dx.light.css';
import DataGrid, {Column} from "devextreme-react/data-grid";
import GeneralInfo from "./Componenets/GeneralInfo";
import HellFireValues from "./Componenets/HellFireValues";
import CameraImages from "./Componenets/CameraImages";
import * as constants from "constants";
import DataGrids from "./Componenets/DataGrids";
import {Popup, Position} from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";


function SwitchDetails(props) {

    // Data of the switch item. Not details.
    // Only PeopleTypeID 2 and 4 can access the start stop buttons for switches.
    // This information is extracted from the cookie made in VB.
    const availableTo = [2, 4]


    // const [peopleTypeID,setPeopleTypeID]                    = useState(1);
    const [details, setDetails]                             = useState([{}]);
    // const [recentScheduleGroups, setRecentScheduleGroups]   = useState([{}]);
    const [recentHistory, setRecentHistory]                 = useState([{}]);


    // const [imageCounter, updateImageCounter]                = useState(1)
    // //const [allPhotosImageURL, updateAllPhotosImageURL]      = useState(`https://phpfrontendsql.azurewebsites.net/imageUpload.php?c=p01&HeaterID=${heaterData.HeaterID}&PhotoNum=1`)
    // const [expandButtonText, setExpandButtonText]           = useState("Show Less");

    const [restartHistory, setRestartHistory]               = useState([{}])
    const [personId, setPersonId]                           = useState(-1);
    const monthArr = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];

    const [heaterStatusText, setHeaterStatusText]                       = useState("");
    // const [isHeaterRestartBtnDisabled, setIsHeaterRestartBtnDisabled]   = useState(false);
    const [alertBoxVisisble, setAlertBoxVisible]                        = useState(false);
    const [alertBoxText, setAlertBoxText]                               = useState("Restart Initiated")

    const [divBackgroundColor, setDivBackgroundColor]                   = useState("");
    const restartTextDivBackgroundColor                                 = ["rgb(50, 205, 50)", "rgb(255,99,71)"]

    const [imageDetails, setImageDetails]                               = useState([{}]);
    const [faultHistory, setFaultHistory]                               = useState([{}]);
    const [heaterData, setHeaterData]                                   = useState({});
    const [runningHistory, setRunningHistory]                           = useState({});
    const [showImageToolBool, setShowImageTool]                         = useState(false);
    const [heaterCommandInformation, setHeaterCommandInformation]       = useState([{}])
    const [heaterStopTime, setHeaterStopTime]                           = useState("-1")

    const styles = {
        container: {
            paddingLeft: "1%",
            paddingRight: "1%",
        },
        restartText:{
            position:"fixed",
            top:"20px",
            left:"35%",
            right:"35%",
            textAlign:"center",
            height:"7%",
            borderRadius:"10px",
            backgroundColor:divBackgroundColor
        },
        titleRow: {
            padding: 10,
            marginBottom: 10,
            fontWeight: "bold",
        },
        row: {
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: 0,
        },
        col: {
            paddingTop: 100,
        },
        blackMode: {
            backgroundColor: "black"
        },
        button: {
            marginRight: 5
        },
        gridHeight: {
            height: "1100px"
        },
        jap:{
            left:"200px",
            width:"20%",
            height:"40%"
        }
    };



    useEffect(() =>{
        if(props.data[0] === undefined){
            return
        }
        setHeaterData(props.data.find(item => item.HeaterID == props.selectedHeater))
    },[props])

    useEffect(() =>{

        switch (heaterData.Status){
            case -999:
                setHeaterStatusText("Heater has not checked in recently")
                //setIsHeaterRestartBtnDisabled(true)
                return
            case -998:
                setHeaterStatusText("Line Voltage is 0")
                //setIsHeaterRestartBtnDisabled(true)
                return

            case -997:
                setHeaterStatusText("Heater is faulted")
                //setIsHeaterRestartBtnDisabled(false)
                return

            case -996:
                setHeaterStatusText("Heater is in the process of a restart")
                //setIsHeaterRestartBtnDisabled(true)
                return;

            case 101:
                setHeaterStatusText("Heater is Running")
                return

            case 99:
                setHeaterStatusText("Heater is purging");
                return

            case 50:
                setHeaterStatusText("Request to start heater is queued up")
                return

            case -50:
                setHeaterStatusText("Heater is running. Request to stop will be sent soon")
                return
            default:
                setHeaterStatusText("Heater is in standby")
                //setIsHeaterRestartBtnDisabled(false)
        }
    },[heaterData])

    useEffect(() =>{

        let arrayOfCookieObjects = document.cookie.split(";")

        for(let object of arrayOfCookieObjects){
            let indiviudalCookieInfo = object.split("=");
            if (indiviudalCookieInfo[1] === ""){ // we are checking if the values havent been put to null
                window.location.href = "/Login";
            }
            else{

                if(indiviudalCookieInfo[0].trim() === "PersonId"){
                    setPersonId(parseInt(indiviudalCookieInfo[1]));
                }
            }
        }

    },[heaterData])

    useEffect(()=>{
        if(heaterData.HeaterID === undefined){
            return;
        }

        getHeaterCommands()
        getGatewayRestartHistory()
        getImageIDs()
        getHeaterLastestReadings()
        getFaultHistory()
        getRunningHistory()
        getHeaterStopTime()

    },[heaterData])



    const getHeaterLastestReadings = () =>{
        // Heaters_Get_WithLatestReadValues_ByHeaterID
        var sec07url = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=sec07&HeaterID=" + heaterData.HeaterID;
        fetch(sec07url)
            .then(response => response.json())
            .then(data => {
                setDetails(data[0]);

            });
    }

    const getRunningHistory = () =>{
        let runningHistoryUrl = 'https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=r03&HeaterID='+ heaterData.HeaterID;

        fetch(runningHistoryUrl)
            .then(response => response.json())
            .then(data => {
                setRunningHistory(data);
            });
    }

    const getHeaterCommands = () =>{
        // Heater_CommandsGetRecent
        var sec09url = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=sec09&HeaterID=" + heaterData.HeaterID;

        fetch(sec09url)
            .then(response => response.json())
            .then(data => {
                setRecentHistory(data);
            });

    }

    const getHeaterStopTime = () =>{
        var r04 = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=r04&GatewaySerialNumber=" + heaterData.GatewaySerialNumber;

        fetch(r04)
            .then(response => response.json())
            .then(data => {
                if (data[0]["Result"] === "100" || data[0]["Result"] === 100 || data.length === 0 || data.length === undefined){
                    // No data if the result === 100
                    setHeaterStopTime("-1")
                    return
                }

                setHeaterCommandInformation(data);
                setHeaterStopTime(getDatee(data[0]["DtDontSendBefore"]["date"]))

            });
    }

    const getGatewayRestartHistory = () => {
        if (heaterData.GatewaySerialNumber !== null) {
            var restartHistoryUrl_r01 = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=r01&GatewaySerialNumber=" + heaterData.GatewaySerialNumber;

            fetch(restartHistoryUrl_r01)
                .then(response => response.json())
                .then(data => {
                    setRestartHistory(data)
                });
        }
    }

    const getFaultHistory = () =>{
        var faultUrl = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=f01&HeaterID=" + heaterData.HeaterID;
        fetch(faultUrl)
            .then(response => response.json())
            .then(data => {
                setFaultHistory(data);
            });
    }

    const showAlertBox = (text,isThisAError) =>{
        setAlertBoxText(text)
        if(isThisAError){
            setDivBackgroundColor(restartTextDivBackgroundColor[1])

        }
        else {
            setDivBackgroundColor(restartTextDivBackgroundColor[0])
        }
        setAlertBoxVisible(true)
        setTimeout(function () {
            setAlertBoxVisible(false)
        }, 4000);
    }

    const getImageIDs = () =>{
        var imageIdsUrl = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=p03&HeaterID=" + heaterData.HeaterID;

        fetch(imageIdsUrl)
            .then(response => response.json())
            .then(data => {
                if(data.length > 0){
                    setImageDetails(data);
                    return;
                }
                return;
            });
    }

    const getDate = (e) => {
        if(e.value === undefined || e.value === null){
            return "N/A"
        }

        let formattedDate = `${e.value.replace(/ /g, 'T')}Z`
        let nDate = new Date(formattedDate)
        let newDatee = `${getMonth(nDate.getMonth())} ${nDate.getDate()} ${nDate.getFullYear()}, ${checkLength(nDate.getHours())}:${checkLength(nDate.getMinutes())}:${checkLength(nDate.getSeconds())}`

        return newDatee

    }

    const getDatee = (e) => {


        let formattedDate = `${e.replace(/ /g, 'T')}Z`
        let nDate = new Date(formattedDate)
        let newDatee = `${getMonth(nDate.getMonth())} ${nDate.getDate()} ${nDate.getFullYear()}, ${checkLength(nDate.getHours())}:${checkLength(nDate.getMinutes())}:${checkLength(nDate.getSeconds())}`

        return newDatee

    }

    const getMonth = (monthIndex) =>{
        return monthArr[monthIndex]
    }

    const checkLength = (passedInTime) =>{
        if(parseInt(passedInTime) < 10){
            return `0${passedInTime}`;
        }
        return passedInTime
    }

    const showFaultCodePopup = () =>{
        props.notVisible()
    }

    const toogleImageTool = () => {
        setShowImageTool(true)
    }

    const hideImageTool = () => {
        setShowImageTool(false);
    }

    const loadInfo = () =>{
        return(
            <ScrollView width='100%' height='100%'>
                <Container>
                    <Row style={styles.row}>
                        <Row>
                            {alertBoxVisisble && <h2 style={styles.restartText}>
                                {alertBoxText}
                            </h2>}
                        </Row>
                        <GeneralInfo
                            heaterStatusText={heaterStatusText}
                            details={details}
                            getGatewayRestartHistory={getGatewayRestartHistory}
                            personId={personId}
                            GatewaySerialNumber={heaterData.GatewaySerialNumber}
                            showAlertBox={showAlertBox}
                            showImage={toogleImageTool}
                            heaterData={heaterData}
                            getDate={getDate}
                            heaterStopTime={heaterStopTime}
                        />

                        {showImageToolBool &&
                            <CameraImages
                                data={heaterData}
                                showAlertBox={showAlertBox}
                                getHeaterCommands={getHeaterCommands}
                                // isRequestPictureDisabled={isHeaterRestartBtnDisabled}
                                imageDetails={imageDetails}
                                showImage={hideImageTool}
                                isVisible={showImageToolBool}
                            />}
                    </Row>
                    <br/>
                    <HellFireValues
                        details={details}
                    />
                    <br/>
                     <DataGrids
                         restartHistory={restartHistory}
                         faultHistory={faultHistory}
                         getDate={getDate}
                         recentHistory={recentHistory}
                         runningHistory={runningHistory}
                     />
                </Container>
            </ScrollView>
        )

    }

    return (
        <Popup
            visible={true}
            dragEnabled={false}
            hideOnOutsideClick={true}
            onHiding={showFaultCodePopup}
            showCloseButton={true}
            showTitle={true}
            title={`Rail Heater at: ${heaterData.heatername}`}
            contentRender={loadInfo}
            width={"95%"}
            height={"95%"}
        >
            <Position
                at="center"
                my="center"
                of='window'
            />
        </Popup>
    );
}




export default SwitchDetails;