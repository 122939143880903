import React, { useState, useEffect } from 'react';
import 'react-medium-image-zoom/dist/styles.css';

import 'devextreme/dist/css/dx.light.css';
import {Col, Row} from "react-bootstrap";
import {Button} from "devextreme-react/button";
import {confirm} from "devextreme/ui/dialog";
import {Popup, Position} from "devextreme-react/popup";
import {SelectBox} from "devextreme-react";



function GeneralInfo(props) {


    const [restartStartedVar, setRestartStartedVar]                         = useState(false);
    const [restartText, setRestartText]                                     = useState("Restart Initiated");
    const [divBackgroundColor, setDivBackgroundColor]                       = useState("");
    const restartTextDivBackgroundColor                                     = ["rgb(50, 205, 50)", "rgb(255,99,71)", "rgb(255, 170, 51)"];

    const [startStopBtnDisabled, setStartStopBtnDisabled]                   = useState(false);
    const [restartBtnDisabled, setRestartBtnDisabled]                       = useState(false);
    const [imageWizardBtn, setImageWizardBtnDisabled]                       = useState(false);
    const [showPopup, setShowPopup]                                         = useState(false);

    const monthArr                                                          = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
    const heaterRunDurations                                                = [15, 30, 45, 60]
    const [userSelectedHeaterRunDuration, setUserSelectedHeaterRunDuration] = useState(-1);
    const [isDurationErrorPresent, setIsDurationErrorPresent]               = useState("hidden");

    const styles = {
        switchButtons: {
            marginTop:"0%"
        }
    }

    function formatLatLong(inputNumber){
        return parseFloat(inputNumber).toFixed(5);
    }

    useEffect(() =>{
        if(props.details === undefined){
            return
        }
        switch (props.details.Status){

            case -999:
                // Not checked in recently
                setStartStopBtnDisabled(true)
                setImageWizardBtnDisabled(true)
                setDivBackgroundColor(restartTextDivBackgroundColor[1])
                return

            case -998:
                // Line voltage is 0
                setStartStopBtnDisabled(true)
                setImageWizardBtnDisabled(true)
                setDivBackgroundColor(restartTextDivBackgroundColor[1])
                return

            case -997:
                // Faulted
                setStartStopBtnDisabled(true)
                setImageWizardBtnDisabled(true)
                setDivBackgroundColor(restartTextDivBackgroundColor[1])
                return

            case -996:
                //Restarting
                setRestartBtnDisabled(true)
                setImageWizardBtnDisabled(true)
                return;

            case 101:
                // Running
                setStartStopBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setDivBackgroundColor(restartTextDivBackgroundColor[0])
                return

            case 99:
                // Purging
                setStartStopBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setDivBackgroundColor("black")
                return

            case 50:
                //Request to start is queue up
                setStartStopBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setDivBackgroundColor("black")
                setDivBackgroundColor(restartTextDivBackgroundColor[2])
                return

            case -50:
                setStartStopBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setDivBackgroundColor("black")
                setDivBackgroundColor(restartTextDivBackgroundColor[2])
                return


            default:
                setStartStopBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setImageWizardBtnDisabled(false)
                setDivBackgroundColor("black")
                return

        }
    },[props])


    const getDate = (e) => {
        if(e === undefined || e === null){
            return "N/A"
        }

        let formattedDate = `${e.replace(/ /g, 'T')}Z`
        let nDate = new Date(formattedDate)
        let newDatee = `${getMonth(nDate.getMonth())} ${nDate.getDate()} ${nDate.getFullYear()}, ${checkLength(nDate.getHours())}:${checkLength(nDate.getMinutes())}:${checkLength(nDate.getSeconds())}`

        return newDatee
    }

    const getMonth = (monthIndex) =>{
        return monthArr[monthIndex]
    }

    const checkLength = (passedInTime) =>{
        if(parseInt(passedInTime) < 10){
            return `0${passedInTime}`;
        }
        return passedInTime
    }

    const showRunDurationPopup = (e) => {
        setShowPopup(true);
    }

    const stopHeater = (e) => {
        let stopHeaterURL = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=c04&HeaterID=" + props.heaterData.HeaterID;

        fetch(stopHeaterURL)
            .then(response => response.json())
            .then(data => {
                if(data[0].Result == 100){
                    props.showAlertBox("Stop request added to queue", false)
                }
                else{
                    console.log(data);
                    props.showAlertBox("Error adding request",true);
                }
            });

    }

    const restartHeater = () =>{
        let result = confirm("<i>Confirm you want to restart heater</i>", "Restart Heater");

        result.then((dialogResult) => {
            if(dialogResult){
                userConfirmedRestart()
                return
            }
            return
        });
    }

    const userConfirmedRestart = () =>{
        var heaterRestartUrl = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=r00&AuthorId="+props.personId+"&GatewaySerialNumber=" + props.GatewaySerialNumber;
        console.log(heaterRestartUrl)

        fetch(heaterRestartUrl)
            .then(response => response.json())
            .then(data => {
                try{
                    console.log(data[0].Result);
                    if(data[0].Result === 100){

                        props.showAlertBox("Restart initiated", false);
                        props.getGatewayRestartHistory()

                    }
                    else if(data[0].Result === -111){
                        props.showAlertBox("Error processing restart", true);

                    }
                    else{
                        console.log(`error. code: ${data[0]}`);
                    }
                    // This is timeout for the get div.
                    // This hsould be moved ?
                    setRestartStartedVar(true)
                    setTimeout(function () {
                        setRestartStartedVar(false)
                    }, 5000);

                }
                catch (e) {
                    console.log(e)
                    console.log("Error")
                }
            });
    }

    const showImageTool = () =>{
        props.showImage()
    }

    const hideStartDurationPopup = () =>{
        if(showPopup === true){
            setShowPopup(false)
            return
        }
    }

    const heaterRunDurationChanged = (e) => {
        console.log(e)
        setUserSelectedHeaterRunDuration(parseInt(e))
    }


    const loadFaultInformation = (e) =>{
        return(
            <div>
                <div style={{borderBottom:"20px"}}>
                    <SelectBox
                        dataSource={heaterRunDurations}
                        onValueChange={heaterRunDurationChanged}
                    />

                </div>
                <div>
                    <div style={{marginTop:"5%"}}>
                        <div style={{ display:"inline-block"}}>  <Button onClick={sendStartRequest}         text={"Send Request"}   disabled={startStopBtnDisabled}/> </div>
                        <div style={{ display:"inline-block"}}>  <Button onClick={hideStartDurationPopup}   text={"Cancel"}         disabled={startStopBtnDisabled}/> </div>
                    </div>
                    <h3 style={{color:"red", visibility: isDurationErrorPresent}}> Please select a heater run duration</h3>
                </div>
            </div>
        )
    }

    const sendStartRequest = () => {
        console.log(userSelectedHeaterRunDuration)

        if(userSelectedHeaterRunDuration === -1){
            console.error("No selected run time")
            setIsDurationErrorPresent("Visible")
            setTimeout(() =>{
                setIsDurationErrorPresent("Hidden")
            }, 6000)
            return
        }
        console.info(  `User selected time is ${userSelectedHeaterRunDuration}`)

        let startHeaterUrl = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=c03&HeaterID=" + props.heaterData.HeaterID + "&UserSelectedRunDuration="+userSelectedHeaterRunDuration;
        console.log(startHeaterUrl)
        fetch(startHeaterUrl)
            .then(response => response.json())
            .then(data => {

                if(data[0].Result == 100){
                    props.showAlertBox("Start request added to queue", false)
                }
                else{
                    props.showAlertBox("Error adding request",true);
                }
            });
    }
//
    return(

        <Col xl={12} lg={12}>

        <Popup
            visible={showPopup}
            dragEnabled={false}
            hideOnOutsideClick={true}
            onHiding={hideStartDurationPopup}
            showCloseButton={true}
            showTitle={true}

            title="Choose start duration (mins)"
            contentRender={loadFaultInformation}
            width={"30%"}
            height={"30%"}

            >
            <Position
                at="center"
                my="center"
                of='window'
            />

        </Popup>

            <Row>
                <h2 style={{color:divBackgroundColor, textAlign:"center"}}>{props.heaterStatusText}</h2>
                {props.heaterStopTime !== "-1" && <h5 style={{color:divBackgroundColor, textAlign:"center"}}>Stop command will be sent approx at: {props.heaterStopTime}</h5>}
                <div style={styles.switchButtons}>
                    <div style={{ display:"inline-block"}}><Button onClick={showRunDurationPopup}     text={"Run Heater"}         disabled={startStopBtnDisabled}/></div>
                    <div style={{ display:"inline-block"}}><Button onClick={stopHeater}     text={"Stop Heater"}        disabled={startStopBtnDisabled}/></div>
                    <div style={{ display:"inline-block"}}><Button onClick={restartHeater}  text={"Restart Heater"}     disabled={restartBtnDisabled}/></div>
                    <div style={{ display:"inline-block"}}><Button onClick={showImageTool}  text={"Show Image Wizard"}  disabled={imageWizardBtn}/></div>
                </div>
                <div>
                    {props.details.LastUpdated !== undefined && <h4>Last Updated at: {getDate(props.details.LastUpdated.date)} </h4>}
                </div>
            </Row>
        </Col>
    )

}





export default GeneralInfo;