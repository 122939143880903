import React, {useEffect} from 'react'
import RadioGroup from 'devextreme-react/radio-group';

function EnCompassAdminBtn (props) {


    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '5%' }}>
                <div className="dx-field">
                    <div className="dx-field-value">
                        <RadioGroup
                            dataSource={props.allCompanies}
                            layout="horizontal"
                            displayExpr="CompanyAbbreviation"
                            valueExpr="CompanyID" // Expr seems to be calling dictonary items
                            onValueChanged={props.onClick}

                        />

                    </div>
                </div>
        </div>
    )
}

export default EnCompassAdminBtn;
