import React, {useEffect, useState} from 'react';
import {Button, TextBox} from "devextreme-react";
import {useLocation, useParams} from "react-router-dom";


function PasswordChangeRequest(props){

    const [username, updateUsername] = useState("");
    const [passwordReset, updatePasswordReset] = useState(true);

    const sendPasswordChangeReq = () => {

        let urlString = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=sec10&"
        urlString +=`PersonEmail=${username}`
        fetch(urlString)
            .then(response => response.json())
            .then(data => {

                if (data[0] === undefined){
                    updatePasswordReset(false); // cuz of html we need to reverse of true / false
                }
            });
    }

    const updateEmail = (props) =>{
        updateUsername(props.value);
    }
    const ignoreEnterEvent = (e) =>{
        e.preventDefault();
    }

    return(
        <div>
            <form onSubmit={ignoreEnterEvent} style={{width:"40%", marginLeft: "30%",marginRight:"30%", border:"0.5px solid black", padding:"10px 10px 10px 10px", boxShadow: "0px 0px 8px 5px rgba(0, 0, 0, .4)"}}>
                <div style={{textAlign:"center", fontSize:"1.3vw"}}>
                    <img  src="./ESGBig.png" style={{ height:"10%",display: "block", marginLeft: "auto", marginRight:"auto",width: "20%"}}/>
                    <br/>
                    Canadian Pacific Railway
                </div>
                <label><b>Email to change password:
                </b>
                </label>
                <TextBox onValueChanged={updateEmail} stylingMode={"filled"} elementAttr={textBoxAttributes} onEnterKey={sendPasswordChangeReq} />
                <br/><br/>
                <Button text="Change Password" onClick={sendPasswordChangeReq} elementAttr={loginButton}/>

                <div style={badLoginStyle} hidden={passwordReset}>
                    If Valid Account Email Will Be Sent For Password Change
                </div>
            </form>
        </div>
    )
}

const badLoginStyle = {
    backgroundColor: "#419c55",
    textAlign: "center",
    marginLeft: "15%",
    marginRight: "15%",
    marginTop:"2%",
    paddingTop:"5px",
    paddingBottom:"5px"

}
const textBoxAttributes = {
    style:"width:100%; background-color: lightgrey;"
}

const loginButton = {
    style:"margin-left:25%;margin-right:25%;width:50%;",
}

export default PasswordChangeRequest;