/*
    Component layout:
        --> Home.js
            --> SwitchDetails.js
                --> GeneralInfo.js
                --> CameraImages.js
                --> HellFireValues.js
                --> DataGrid.js

        Component Info:
        - Home.js
            - The page you land on when you see all heaters
        - SwitchDetails.js
            - the page that shows up when you click on a heater
        - GeneralInfo.js
            - Heater buttons and the status of the heater
        - CameraImages.js
            - The image popup wizard. Request, look at pictures
        - HellFireValues.js
            - The manually created table that shows all the values from the board
        - DataGrid.js
            - Multiple tables: Fault, Running, Restart history, and Heater Information
 */

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import DataGrid, { Column, MasterDetail, Scrolling } from 'devextreme-react/data-grid';
import ScrollView from 'devextreme-react/scroll-view';

import EnCompassAdminBtn from './Components/enCompassAdminBtn.js'
import SwitchDetails from './Components/SwitchDetails/SwitchDetails';
import Navbar from "../Navbar/Navbar";
import $ from 'jquery';
import {SelectBox} from "devextreme-react";

function Home(props) {

    const [heatersData, setHeatersData] = useState([{}]);
    const [expandButtonText, setExpandButtonText] = useState("Show More");
    const [mapCenter, setMapCenter] = useState([{latitude: 49.25708387012965, longitude: -122.7309580694427}]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [companies,setCompanies] = useState([{}]);
    const [pins, setPins] = useState([{ "center": { latitude: 49.25708387012965, longitude: -122.7309580694427}, "options": { color: "red" } }]);
    const [companyID,UpdateCompanyId] = useState(0);
    const [isAdmin, updateAdmin] = useState(false);

    const [doNotUpdate, willNotUpdate] = useState(false);
    const monthArr = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
    const [selectedData, setSelectedGatewaySN] = useState();

    const [isVisibsle, setIsVisible] = useState(false);
    const[selectedHeaterID, setSelectedHeaterID] = useState(-1);

    useEffect( () =>{

        reloadHeaterData()
        // 5550 means an admin is logged in. If it is not an admin we set companyID with the userLevel ie you are logged in as CPR
        if (companyID === 5500) {
            updateAdmin(true);
            return;
        }

    },[companyID])



    useEffect(() => {

        if(document.cookie === ""){
            window.location.href = "/Login";
        }

        let arrayOfCookieObjects = document.cookie.split(";")

        if(arrayOfCookieObjects.length !== 4){ // this will eventaully be put to 4 if we put expirey date in
            window.location.href = "/Login";
        }

        let email       = "";
        let personType  = "";
        let compID      = "";
        let personID    = "";

        for(let object of arrayOfCookieObjects){
            let individualCookieInfo = object.split("=");

            if (individualCookieInfo[1] === ""){ // we are checking if the values havent been put to null
               window.location.href = "/Login";
            }
            else{
                switch(individualCookieInfo[0].trim()){
                    case "PeopleTypeID":
                        personType = individualCookieInfo[1]
                        continue;

                    case "PersonEmail":
                        email = individualCookieInfo[1]
                        continue;

                    case "CompanyID":
                        compID = individualCookieInfo[1]
                        continue;

                    case "PersonId":
                        personID = individualCookieInfo[1]
                        continue;

                    default:
                        return;
                }
            }
        }
        UpdateCompanyId(parseInt(compID));
    }, [doNotUpdate]);

    useEffect(() => {
        //Used to get all companies except enCompass from DB to help with switching websites
        fetch('https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=c02')
            .then(response => response.json())
            .then(data => {
                setCompanies(data);
            })
    }, [])


    const changeCompanyView = (event) =>{ // this is called by enCompass admim btn
        UpdateCompanyId(parseInt(event.value));
    }

    const reloadHeaterData = () =>{
        let url = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=h02&CompanyID=" + companyID;

        fetch(url)
            .then(response => response.json())
            .then(data => {

                if (data.length === 0){
                    setDataLoaded(true);
                    setHeatersData([])
                    return
                }
                setHeatersData(data);
                setDataLoaded(true);
            });
    }

    const setupRow = (e) =>{

        if(e.data === undefined){
            return
        }

        if((e.data.Status === -999 || e.data.Status === -998 || e.data.Status === -997)){
            e.rowElement.style.backgroundColor = "#ff4d4d"
        }
       else if(e.data.Status === -996 || e.data.Status === 50){
            e.rowElement.style.backgroundColor = "yellow";
        }
        else{
            e.rowElement.style.backgroundColor="";
            e.rowElement.style.color="";
        }
    }

    const rowRenderSetStatus = (e) =>{

        switch (e.key.Status){

            case -999:
                return "Heater has not checked in recently";

            case -998:
                return "Line Voltage is 0";

            case -997:
                return "Heater is faulted";

            case -996:
                return "Heater is in the process of a restart";

            case 101:
                return "Heater is running";

            case 50:
                return "Request to start heater is queued up"

            case -50:
                return "Heater is running. Request to stop is queued"

            default:
                return "Heater is in standby";
        }

    }

    const getDateTime = (e) => {
        if (e.data.LastUpdated.date === undefined || e.data.LastUpdated.date === null) {
            return "N/A"
        }

        let formattedDate = `${e.data.LastUpdated.date.replace(/ /g, 'T')}Z`
        let nDate = new Date(formattedDate)
        let newDatee = `${getMonth(nDate.getMonth())} ${nDate.getDate()} ${nDate.getFullYear()}, ${checkLength(nDate.getHours())}:${checkLength(nDate.getMinutes())}:${checkLength(nDate.getSeconds())}`

        return newDatee
    }

    const getMonth = (monthIndex) =>{
        return monthArr[monthIndex]
    }

    const checkLength = (passedInTime) =>{
        if(parseInt(passedInTime) < 10){
            return `0${passedInTime}`;
        }
        return passedInTime
    }

    useEffect(()=>{
        reloadHeaterData()

        const interval=setInterval(()=>{
            reloadHeaterData()
        },6000)

        return()=>clearInterval(interval)

    },[companyID])

    const rowClick = (e) =>{
        // console.log(e.data.HeaterID);
        setSelectedHeaterID(e.data.HeaterID)
        setSelectedGatewaySN(e.data)
        setIsVisible(true)
    }

    const notVisible = () =>{
        setIsVisible(false);
    }


    if (!dataLoaded) {
        // move me to the middle of page
        return (<div style={{alignItem:"center", display:"flex", justifyContent:"center", marginTop:"25%"}}><h1>Loading</h1></div>)
    } else {
        return (
            <div className="App">
                <Navbar active={1}/>
                <div className="App-body">
                    <Container fluid>
                        <Row>{ isAdmin === true && companies.length > 0 && <EnCompassAdminBtn allCompanies={companies} onClick={changeCompanyView}/> }</Row>
                        <Row style={styles.titleRow}> <h2>Switch Heaters</h2>  </Row>
                        <Row bsPrefix="row align-items-start">

                            <Col lg={12}>

                                <ScrollView width='100%' height='50%'>
                                    <DataGrid
                                        height={$(window).height() * 0.8}
                                        dataSource={heatersData}
                                        repaintChangesOnly={true}
                                        showColumnLines={true}
                                        showRowLines={true}
                                        wordWrapEnabled={true}
                                        onRowPrepared={setupRow}
                                        onRowClick={rowClick}
                                        // onRowExpanding={function (e) {
                                        //     e.component.collapseAll(-1);
                                        //     setExpandButtonText("Show More");
                                        //     //setMapCenter({ latitude: e.key.HeaterLatitude, longitude: e.key.HeaterLongitude }) // does thing belong here ?
                                        // }}
                                    >
                                        <Column dataField="heatername" caption="Name" alignment="center" />
                                        <Column dataField="Address" alignment="center" />
                                        <Column dataField="heaterStatus" caption="Status" alignment="center" allowEditing={false} format={{ type: 'fixedPoint', precision: 5 }} cellRender={rowRenderSetStatus} />
                                        <Column dataField="HeaterLocationName" caption="Location" alignment="center" />
                                        <Column dataField= "LastUpdated" caption="Last Seen" alignment="center" allowEditing={false} format="string" cellRender={getDateTime} />
                                        <Scrolling mode="infinite" />
                                        {/*<MasterDetail*/}
                                        {/*    enabled={true}*/}
                                        {/*    autoExpandAll={false}*/}
                                        {/*    component={SwitchDetails}*/}
                                        {/*    data={null}*/}
                                        {/*/>*/}
                                    </DataGrid>
                                </ScrollView>

                                {isVisibsle &&
                                    <SwitchDetails
                                        data={heatersData}
                                        notVisible={notVisible}
                                        selectedHeater={selectedHeaterID}
                                />}



                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}
const styles = {
    titleRow: {
        padding: 10,
        marginBottom: 10,
        fontWeight: "bold",
    },
    row: {
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 10,
    },
    col: {
        paddingTop: 100,
    },
    blackMode: {
        backgroundColor: "black"
    },
    button: {
        marginRight: 5
    },
    gridHeight: {
        height: "1100px"
    },
    cellHighlighted: {
    color: "gray"
    }
};

export default Home;