import React, { useState, useEffect } from 'react';
import 'react-medium-image-zoom/dist/styles.css';

import 'devextreme/dist/css/dx.light.css';
import {Col, Row} from "react-bootstrap";
import {Button} from "devextreme-react/button";
import {confirm} from "devextreme/ui/dialog";
import { Container } from 'react-bootstrap';
import Popup, {Position} from 'devextreme-react/popup';

function HellFireValues(props) {

    const [showPopup, setShowPopup] = useState(false);
    const [faultInformation, setFaultInformation] = useState([{}]);


    useEffect(() =>{
    },[props])

    const showFaultCodePopup = () =>{
        if(showPopup === true){

            setShowPopup(false)
            return
        }
        getFaultCodeInfo()

    }

    const getFaultCodeInfo = () =>{
        if(props.details.FaultCode === undefined || props.details.FaultCode == 0){
            return
        }

        var faultCodeUrl = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=f00&FaultCodeID=" + props.details.FaultCode;
        fetch(faultCodeUrl)
            .then(response => response.json())
            .then(data => {

                if(data[0] !== undefined){
                    setShowPopup(true)
                    setFaultInformation(data);
                }
                else{
                    setShowPopup(true)
                }

        })
    }

    const loadFaultInformation = (e) =>{
        if(faultInformation[0].AlarmType === undefined){
            return(
                <div>
                    <p>Error getting fault information</p>
                </div>
            )
        }
        return(
            <div>
                <p>Alarm Type: {faultInformation[0].AlarmType}</p>
                <p>{faultInformation[0].FaultCondition}</p>
            </div>
        )
    }
    return(
        <Container>

            <Popup
                visible={showPopup}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                showTitle={true}
                title="Fault Detected"
                contentRender={loadFaultInformation}
                onHiding={showFaultCodePopup}
                width={"40%"}
                height={"30%"}
            >
                <Position
                    at="center"
                    my="center"
                    of='window'
                />
            </Popup>

            <table>
                <caption><h3>Discrete Values From Hell Fire</h3></caption>
                <tbody>
                <tr>

                    <td>{props.details.Status == "-997" ?
                        <div><span style={stylesSpan.spanLeftTdBol}>Flag Fault Code:</span>{props.details.FlagFaultCode}  <Button onClick={showFaultCodePopup} icon={"warning"}/></div>:
                        <div><span style={stylesSpan.spanLeftTd}>Flag Fault Code:</span>{props.details.FlagFaultCode}</div>
                    }</td>
                    {/*We should highlight thsi soo*/}
                    <td><span style={stylesSpan.spanLeftTd}>SCD Enabled:</span>{props.details.FlagSCDEnable}</td>
                    <td>{props.details.Status == "101" ?
                        <div><span style={stylesSpan.spanLeftRunning}>A1A2:</span>{props.details.FlagA1A2}</div>:
                        <div><span style={stylesSpan.spanLeftTd}>A1A2:</span>{props.details.FlagA1A2}</div>
                    }</td>

                    <td><span style={stylesSpan.spanLeftTd}>Alarm FSR:</span>{props.details.FlagAlarmFSR}</td>
                </tr>
                <tr>
                    <td><span style={stylesSpan.spanLeftTd}>SCD Rail:</span>{props.details.FlagSCDRail}</td>
                    <td><span style={stylesSpan.spanLeftTd}>Timed Manual:</span>{props.details.FlagTimedManual}</td>

                    <td>{props.details.Status == "101" ?
                        <div><span style={stylesSpan.spanLeftRunning}>Air SW:</span>{props.details.FlagAir}</div>:
                        <div><span style={stylesSpan.spanLeftTd}>Air SW:</span>{props.details.FlagAir}</div>
                    }</td>

                    <td><span style={stylesSpan.spanLeftTd}>High:</span>{props.details.FlagHigh}</td>
                </tr>
                <tr>
                    <td><span style={stylesSpan.spanLeftTd}>Central EMS:</span>{props.details.FlagCentralEMS}</td>
                    <td><span style={stylesSpan.spanLeftTd}>Manual:</span>{props.details.FlagManual}</td>
                    <td>{props.details.Status == "101" ?
                        <div><span style={stylesSpan.spanLeftRunning}>Feedback:</span>{props.details.FlagFeedBack}</div>:
                        <div><span style={stylesSpan.spanLeftTd}>Feedback:</span>{props.details.FlagFeedBack}</div>
                    }</td>

                    <td><span style={stylesSpan.spanLeftTd}>Alarm Ind:</span>{props.details.FlagAlarmInd}</td>
                </tr>
                <tr>
                    <td><span style={stylesSpan.spanLeftTd}>EMS Enabled:</span>{props.details.FlagLocalEMSEna}</td>
                    <td><span style={stylesSpan.spanLeftTd}>CM1:</span>{props.details.FlagCM1}</td>
                    <td>{props.details.Status == "101" ?
                        <div><span style={stylesSpan.spanLeftRunning}>Motor:</span>{props.details.FlagMotor}</div>:
                        <div><span style={stylesSpan.spanLeftTd}>Motor:</span>{props.details.FlagMotor}</div>
                    }</td>
                    <td><span style={stylesSpan.spanLeftTd}>Cycle SW:</span>{props.details.FlagCyclingSW}</td>
                </tr>
                </tbody>
            </table>

            <table>
                <caption><h3>Analog Values From Hell Fire</h3></caption>
                <tbody>
                    <tr>
                        <td>{props.details.Status == "-998" ?
                            <div><span style={stylesSpan.spanLeftTdBol}>Line V:</span>{props.details.LVolt}</div> :
                            <div><span style={stylesSpan.spanLeftTd}>Line V: </span>{props.details.LVolt}</div>
                        }</td>
                        <td>{props.details.Status == "-997" ?
                            <div><span style={stylesSpan.spanLeftTdBol}>Fault Code:</span>{props.details.FaultCode}  <Button onClick={showFaultCodePopup} icon={"warning"}/></div>:
                            <div><span style={stylesSpan.spanLeftTd}>Fault Code:</span>{props.details.FaultCode}</div>
                        }</td>
                        <td><span style={stylesSpan.spanLeftTd}>Amb Temp:</span>{props.details.Ambt}</td>

                        <td><span style={stylesSpan.spanLeftTd}>Snow/Rain:</span>{props.details.Snow_Rain}</td>
                    </tr>

                    <tr>
                        <td>{props.details.Status == "101" ?
                            <div><span style={stylesSpan.spanLeftRunning}>Flag Run Ind:</span>{props.details.FlagRunInd}</div>:
                            <div><span style={stylesSpan.spanLeftTd}>Flag Run Ind:</span>{props.details.FlagRunInd}</div>
                        }</td>
                        <td><span style={stylesSpan.spanLeftTd}>Line A:</span>{props.details.LAmp}</td>
                        <td><span style={stylesSpan.spanLeftTd}>Delay T:</span>{props.details.DelayT}</td>
                        <td><span style={stylesSpan.spanLeftTd}>Time Manual:</span>{props.details.TimeManual}</td>
                    </tr>
                    <tr>
                        <td><span style={stylesSpan.spanLeftTd}>Post T:</span>{props.details.PostT}</td>
                        <td><span style={stylesSpan.spanLeftTd}>Manual Time:</span>{props.details.ManualTime}</td>
                        <td><span style={stylesSpan.spanLeftTd}>RTC Time:</span>{props.details.RTC_Time}</td>
                        <td><span style={stylesSpan.spanLeftTd}>Wet Value:</span>{props.details.Snow_Rain}</td>
                    </tr>
                </tbody>
            </table>
        </Container>
    )
}

const stylesSpan = {
    spanLeftTd : {
        display:"inline-block",
        width:"30%"
    },
    spanLeftTdBol : {
        display: "inline-block",
        width: "30%",
        fontWeight:"Bold",
        textDecoration:"Underline",
        color:"red"
    },
    spanRightTd :{
        width:"40%",
    },
    spanLeftRunning : {
        display: "inline-block",
        width: "30%",
        fontWeight:"Bold",
        textDecoration:"Underline",
        color:"rgb(50, 205, 50)"
    }
}


export default HellFireValues;