import React, {useEffect, useState} from 'react';
import {Button, TextBox} from "devextreme-react";
import {useLocation, useParams} from "react-router-dom";
import {
    Validator,
    RequiredRule,
    PatternRule,
    StringLengthRule} from 'devextreme-react/validator';
import ValidationSummary from 'devextreme-react/validation-summary';
import md5 from "md5";


function PasswordChangeRequest(props){

    const [password, updatePassword] = useState("");
    const [tokenWasValid, updateUserToken] = useState(false);
    const [doNotUpdate, doNotUpdateMeth] = useState(false);
    const [regexPattern, doNotUpdateRegexPattern] = useState("^(?=(.*\\d){1})(?=.*[a-z])(?=.*[A-Z]).{8,}$");
    const [passwordStatus,updatePasswordStatus] = useState(false);
    const [passwordUpdatedSucessfully, updatePasswordUpdatedSucessfully] = useState(true); // this is used for a HTML tag and they use Hideen attr so we start as true and then go false

    let { personID } = useParams();
    let {validationCode} = useParams();

    useEffect(() => {
        let urlString = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=sec11&"
        urlString += `ValidationCode=${validationCode}&PersonID=${personID}`

        fetch(urlString)
            .then(response => response.json())
            .then(data => {
                if (data[0].PersonID === parseInt(personID)){

                    updateUserToken(true);
                }
                else{
                    console.log("Token was not valid")
                    updateUserToken(false);
                }
            })
            .catch((error) => {
            console.error('Error from DB');
        });

    }, [doNotUpdate])

    const sendPasswordChangeReq = () => {
        console.log(password)
        let urlString = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=sec12&"
        urlString +=`ValidationCode=${validationCode}&PersonID=${personID}&PersonPin=${md5(password)}`
        console.log(urlString)
        fetch(urlString)
            .then(response => response.json())
            .then(data => {
                if (data[0].Response === 100){
                    updatePasswordUpdatedSucessfully(false)
                    setTimeout(function(){
                        // eslint-disable-next-line no-restricted-globals
                        location.href="/"
                    }, 2000);
                }
            });
    }

    const updatePasswordValue = (props) =>{
        updatePassword(props.value);
    }

    const passwordValid = (args) =>{
        if(args.status === "valid"){
            updatePasswordStatus(true)
        }
        else {
            updatePasswordStatus(false)
        }
    }
    const ignoreEnterEvent = (e) =>{
        e.preventDefault();
    }

    return(
        <div>
            <form onSubmit={ignoreEnterEvent} style={{width:"40%", marginLeft: "30%",marginRight:"30%", border:"0.5px solid black", padding:"10px 10px 10px 10px", boxShadow: "0px 0px 8px 5px rgba(0, 0, 0, .4)"}}>
                <div style={{textAlign:"center", fontSize:"1.6vw"}}>
                    <img  src="https://storageaccountcprsw9ac6.blob.core.windows.net/esglogo/ESGBig.png?sp=r&st=2021-11-23T00:00:31Z&se=2023-12-31T08:00:31Z&sv=2020-08-04&sr=b&sig=EsKi8J1t7WlG7VTcF3OJeC5HyYM66IrVfT2BJ1JEG3M%3D" style={{ height:"10%",display: "block", marginLeft: "auto", marginRight:"auto",width: "20%"}}/>
                    <br/>
                    Canadian Pacific Railway
                </div>
                <label hidden={!tokenWasValid}><b>Enter new password
                </b>
                </label>
                <TextBox onValueChanged={updatePasswordValue} stylingMode={"filled"} elementAttr={textBoxAttributes} visible={tokenWasValid} mode={"password"} onEnterKey={sendPasswordChangeReq}>
                    <Validator onValidated={passwordValid}>
                        <RequiredRule message="Password is required" />
                        <PatternRule message="Must contain at least 1 lower, 1 upper, 1 number and min length of 8." pattern={regexPattern} />
                        <StringLengthRule message="Name must have at least 8 symbols" min={8} />
                    </Validator>
                </TextBox>

                <br/><br/>
                <Button text="Change Password" onClick={sendPasswordChangeReq} elementAttr={loginButton} visible={tokenWasValid} disabled={!passwordStatus}/>
                <Button text="Token Is Invalid Or Previously Used" disabled={true} elementAttr={loginButton} visible={!tokenWasValid} style={{color:"Black"}}
                />
                <div style={badLoginStyle} hidden={passwordUpdatedSucessfully}>
                    Password Changed
                    <br/>
                    <a href={"/login"}>Login</a>
                </div>
            </form>
        </div>
    )
}

const badLoginStyle = {
    backgroundColor: "#419c55",
    textAlign: "center",
    marginLeft: "25%",
    marginRight: "25%",
    marginTop:"2%",
    paddingTop:"5px",
    paddingBottom:"5px"
}
const textBoxAttributes = {
    style:"width:100%; background-color: lightgrey;"
}

const loginButton = {
    style:"margin-left:25%;margin-right:25%;width:50%;",
}

export default PasswordChangeRequest;