import React, { useState, useEffect } from 'react';
import 'react-medium-image-zoom/dist/styles.css';

import 'devextreme/dist/css/dx.light.css';
import {Col, Row} from "react-bootstrap";
import {Button} from "devextreme-react/button";
import {confirm} from "devextreme/ui/dialog";
import { Container } from 'react-bootstrap';
import Popup, {Position} from 'devextreme-react/popup';
import DataGrid, {Column} from "devextreme-react/data-grid";

function DataGrids(props) {
    const styles = {
        container: {
            paddingLeft: "1%",
            paddingRight: "1%",
        },

        titleRow: {
            padding: 10,
            marginBottom: 10,
            fontWeight: "bold",
        },
        row: {
            paddingTop: 0,
            paddingBottom: 0,
            marginBottom: 0,
        },
        col: {
            paddingTop: 100,
        },
        blackMode: {
            backgroundColor: "black"
        },
        button: {
            marginRight: 5
        },
        gridHeight: {
            height: "1100px"
        },
        jap:{
            left:"200px",
            width:"20%",
            height:"40%"
        }
    };

    // const test = (e) =>{
    //
    //     if(e.value === "" || e.value === undefined || e.value === null){
    //         return <div style={{backgroundColor:"rgb(244, 206, 14)", color:"gray" }}>N/A</div>
    //     }
    //     return e.text
    // }

    return(
        <div id="extraDetails">
            <Container xl={12} lg={12} md={12}>
                <Row style={styles.row}>
                    <Col>

                        <h4>Fault History</h4>
                        <DataGrid dataSource={props.faultHistory}  showColumnLines={true}    showRowLines={true}    wordWrapEnabled={true} >
                            <Column dataField="AlarmType"         caption="Fault Code"      alignment={"center"}   width={"10%"}/>
                            <Column dataField="FaultCondition"    caption="Fault Condition" alignment={"center"}   />
                            <Column dataField="insertedDT.date"   customizeText={props.getDate}   caption="Start Date"   alignment={"center"} width={"20%"}/>
                            <Column dataField="outOfFaultDT.date" customizeText={props.getDate}   caption="Out of Fault" alignment={"center"} width={"20%"}/>
                            {/*Below is row to change the HTML of row if needed*/}
                            {/*<Column dataField="outOfFaultDT.date" customizeText={props.getDate}   caption="Out of Fault" alignment={"center"} width={"20%"} cellRender={test}/>*/}
                        </DataGrid>
                    </Col>
                </Row>
                <Row style={styles.row}>
                    <Col>
                        <h4>Running History</h4>
                        <DataGrid dataSource={props.runningHistory} showColumnLines={true} showRowLines={true} wordWrapEnabled={true} highlightChanges={true}>

                            <Column dataField="RequestValue" dataType={"string"} caption={"Requested By"} alignment={"center"}/>
                            <Column dataField="dtHeaterTurningOn.date"  alignment={"center"} customizeText={props.getDate} caption={"Startup initiated"}/>
                            <Column dataField="dtFullyOn.date"  alignment={"center"} customizeText={props.getDate} caption={"Heater Fully On"}/>

                            <Column dataField="dtPostPurgeStarted.date"  alignment={"center"} customizeText={props.getDate} caption={"Purge Started"}/>
                            <Column dataField="dtHeaterOff.date"  alignment={"center"} customizeText={props.getDate} caption={"Purge Complete"}/>
                            {/*<Column dataField="DtCompleted.date"  alignment={"center"} customizeText={props.getDate} caption={"Board online at"}/>*/}

                        </DataGrid>
                    </Col>
                    <Col>
                        <h4>Heater Information</h4>
                        <DataGrid dataSource={props.recentHistory} showColumnLines={true}        showRowLines={true} wordWrapEnabled={true} >
                            <Column dataField="InsertedDT.date"    customizeText={props.getDate} caption="Event Date" alignment={"center"}/>
                            <Column dataField="HeaterCommandName"  caption="Command"             alignment={"center"}/>
                            <Column dataField="CommandStateName"   caption="Command Status"      alignment={"center"}/>
                        </DataGrid>
                    </Col>
                    <Col>
                        <h4>Restart History</h4>
                        <DataGrid dataSource={props.restartHistory} showColumnLines={true} showRowLines={true} wordWrapEnabled={true} highlightChanges={true}>

                            <Column dataField="FullName" dataType={"string"} caption={"Requested By"} alignment={"center"}/>
                            <Column dataField="DtRequested.date"  alignment={"center"} customizeText={props.getDate} caption={"Restart Requested At"}/>
                            <Column dataField="DtRequestSentToInHand.date"  alignment={"center"} customizeText={props.getDate} caption={"Request Sent to Device at"}/>

                            <Column dataField="DtOfflineHeaterConfirmed.date"  alignment={"center"} customizeText={props.getDate} caption={"Board went offline at"}/>
                            <Column dataField="DtStartBoardSent.date"  alignment={"center"} customizeText={props.getDate} caption={"Power on request sent at"}/>
                            <Column dataField="DtCompleted.date"  alignment={"center"} customizeText={props.getDate} caption={"Board online at"}/>

                        </DataGrid>
                    </Col>

            </Row>
        </Container>
    </div>
    )


}

const stylesSpan = {
    spanLeftTd : {
        display:"inline-block",
        width:"30%"
    },
    spanLeftTdBol : {
        display: "inline-block",
        width: "30%",
        fontWeight:"Bold",
        textDecoration:"Underline",
        color:"red"
    },
    spanRightTd :{
        width:"40%",
    }
}


export default DataGrids;