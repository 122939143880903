import React, {useCallback, useEffect, useState} from 'react';
import DataGrid, { Column, Editing, Form } from 'devextreme-react/data-grid';
import {
    SimpleItem,
    GroupItem
} from 'devextreme-react/form';

function HeaterConfig(props) {

    const styles = {
        title: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 20,
            marginBottom: 10,
            fontWeight: "bold",
        },
    }
    const [heaters, setHeaters] = useState([{}]);

    const onRowUpdated = useCallback((e) => {
        var urlString = "https://phpfrontendsql.azurewebsites.net/edsInHand_v2.php?c=sec06"
        urlString += "&HeaterName=" + e.data.HeaterName + "&Address=" + e.data.Address + "&HeaterLocationName=" + e.data.HeaterLocationName + "&HeaterID=" + e.data.HeaterID;
        fetch(urlString)
            .then(response => response.json())
            .then(data => {
                console.log(data);
            });
    }, []);

    useEffect(() => {
        // Get Heaters Config
        if (props.companyID === 0) {
            return
        }
        let apiURL = `https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=h02&CompanyID=${props.companyID}`
        console.log(apiURL)
        fetch(apiURL)
            .then(response => response.json())
            .then(data => {
                setHeaters(data);
            });
    },[props.companyID])

    return (
        <div>
            <div style={styles.title}>
                <h2>Heater Configuration</h2>
            </div>
            <DataGrid
                dataSource={heaters}
                showColumnLines={false}
                showRowLines={true}
                onRowUpdated={onRowUpdated}
                wordWrapEnabled={true}
            >
                <Editing
                    allowUpdating={true}
                    allowAdding={false}
                    mode="form"
                >
                    <Form>
                        <SimpleItem dataField="HeaterName" isRequired={true} />
                        <SimpleItem dataField="Unknown" isRequired={true} />
                        <SimpleItem dataField="Address" isRequired={true} />
                    </Form>

                </Editing>
                <Column dataField="HeaterID" caption="ID" alignment="center" allowEditing={false} />
                <Column dataField="HeaterName" caption="HeaterName" alignment="center" />
                <Column dataField="Address" alignment="center" />
                <Column dataField="HeaterLongitude" caption="Longitude" alignment="center" allowEditing={false} format={{ type: 'fixedPoint', precision: 5 }}/>
                <Column dataField="HeaterLatitude" caption="Latitude" alignment="center" allowEditing={false} format={{ type: 'fixedPoint', precision: 5 }}/>
                <Column dataField="HeaterLocationName" caption="Location" alignment="center" />
                <Column dataField="InsertedDT.date" caption="Inserted On" alignment="center" allowEditing={false} dataType="datetime"/>
                <Column dataField="FirstSeenLiveDT" caption="First Seen Live" alignment="center" allowEditing={false}/>
                <Column dataField="GatewaySerialNumber" alignment="center" allowEditing={false}/>
                <Column dataField="Geolocation" alignment="center" allowEditing={false}/>
                <Column dataField="edsCodeVersion" alignment="center" allowEditing={false}/>
                <Column dataField="GatewayFirmwareVersion" alignment="center" allowEditing={false}/>
                <Column dataField="PythonVersion" alignment="center" allowEditing={false}/>
            </DataGrid>

        </div>
    )
}

export default HeaterConfig;