import React, {useState, useEffect, useCallback} from 'react';
import {TextBox, Button} from "devextreme-react";
import md5 from 'md5';

//Todo:
// Assign cookies and pull cookies
// Show login and bad login
// move to next page
// check on next page

function Login(props){
    const [username, updateUsername] = useState("");
    const [password, updatePassword] = useState("");
    const [checkCookie, updateCookie] = useState(false);
    const [badLogin, updateBadLogin] = useState(true);

    useEffect (() =>{
        console.log(document.cookie)
    },[checkCookie])


    const attemptLogin = () => {
        let urlString = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=l01&"
        urlString += `PersonEmail=${username}&PersonPin=${md5(password)}&CurrentDeviceToken=a&OldDeviceToken=a&BundleID=a`
        console.log(urlString)
        fetch(urlString)
            .then(response => response.json())
            .then(data => {
                if (data[0].Validated !== 1 ){
                    console.log(document.cookie)
                    console.log("Failure");
                    updateBadLogin(false);
                }
                else if (data[0].Validated === 1){

                    document.cookie = "PersonEmail="    +data[0].PersonEmail+";";
                    document.cookie = "PeopleTypeID="   +data[0].PeopleTypeID+";";
                    document.cookie = "CompanyID="      +data[0].CompanyID+";";
                    document.cookie = "PersonId="       +data[0].PersonID+";";

                    updateBadLogin(true)
                    window.location.href = "/";
                }
            });
    }

    const updateEmail = (props) =>{
        updateUsername(props.value);
    }

    const updatePass = (props) =>{
       updatePassword(props.value)
    }




return (
    <div>
        <form style={{width:"40%", marginLeft: "30%",marginRight:"30%", border:"0.5px solid black", padding:"10px 10px 10px 10px", boxShadow: "0px 0px 8px 5px rgba(0, 0, 0, .4)"}}>
            <div style={{textAlign:"center", fontSize:"1.3vw"}}>
                <img  src="./ESGBig.png" style={{ height:"10%",display: "block", marginLeft: "auto", marginRight:"auto",width: "20%"}}/>
                <br/>
                Canadian Pacific Railway
            </div>
            <label><b>User Name
            </b>
            </label>
            <TextBox onValueChanged={updateEmail} stylingMode={"filled"} elementAttr={textBoxAttributes}/>
                <br/><br/>
                <label><b>Password
                </b>
                </label>

            <TextBox onValueChanged={updatePass} mode={"password"} stylingMode={"filled"} elementAttr={textBoxAttributes} onEnterKey={attemptLogin} />
                    <br/><br/>
            <a href="/PasswordChangeRequest">Change Password</a>
            <Button text="Login" onClick={attemptLogin} elementAttr={loginButton}/>

            <div style={badLoginStyle} hidden={badLogin}>
                Credentials didnt match
            </div>
        </form>
    </div>

    )
}


const badLoginStyle = {
    backgroundColor: "#ff4747",
    textAlign: "center",
    marginLeft: "25%",
    marginRight: "25%",
    marginTop:"2%",
    paddingTop:"5px"
}
const textBoxAttributes = {
    style:"width:100%; background-color: lightgrey;"
}

const loginButton = {
    style:"margin-left:25%;margin-right:25%;width:50%;",
}



export default Login;