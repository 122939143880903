import React, { useState, useEffect } from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import {Popup, Position} from 'devextreme-react/popup';
import 'devextreme/dist/css/dx.light.css';
import {Col, Row} from "react-bootstrap";
import {Button} from "devextreme-react/button";



function CameraImages(props) {


    const [imageID, setImageId]                             = useState(-1)
    const [dictionaryId, setDictionaryId]                   = useState(0)
    const [pictures, setPictures]                           = useState([{}]);

    const [btnsHiddemBool, setBtnsHiddenBool]               = useState(true);
    const [imageTakenText, setImageTakenText]               = useState("");
    const [imgTagHidden, setIsImgTagHidden]                 = useState(true);

    const [numberOfImages, setNumberOfImages]               = useState(0);
    const monthArr = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
    let latestImageUrl = `https://phpfrontendsql.azurewebsites.net/imageUpload.php?c=p00&ImageID=${imageID}`;


    useEffect(() =>{
        checkImageData()
        getDateTakenText()
        checkButtons()
    },[props])


    useEffect(() =>{
        setImageId(props.imageDetails[dictionaryId].ImageID)
        latestImageUrl = `https://phpfrontendsql.azurewebsites.net/imageUpload.php?c=p00&ImageID=${imageID}`
        getDateTakenText()

    },[dictionaryId])


    const checkImageData = () =>{

        if(props.imageDetails.length === numberOfImages && numberOfImages > 0){
            // this is to prevent a user being like 3 pictures back and this func running and pushing them first picture every 5 seconds.
            // console.log("Same length returning.")
            return
        }

        if(props.imageDetails[0].ImageID !== undefined){
            setNumberOfImages(props.imageDetails.length)
            setImageId(props.imageDetails[0].ImageID)
            setIsImgTagHidden(false)
        }
        else{
            setNumberOfImages(0)
            setIsImgTagHidden(true)
        }
    }

    const requestPicture = () =>{
        var sec07url = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=r02&HeaterID=" + props.data.HeaterID;
        fetch(sec07url)
            .then(response => response.json())
            .then(data => {

                if(data[0].Status == 100){ //inteiontally only two ==
                    props.showAlertBox("Request added to Queue", false)
                }
                else{
                    console.log(data[0])
                }
                console.log(data);
            });
    }

    const getDateTakenText = () =>{
        if (props.imageDetails[dictionaryId].InsertedDT === undefined){
            setImageTakenText("No Recent Images");
            return
        }
        let re = getDate(props.imageDetails[dictionaryId].InsertedDT.date)
        setImageTakenText(`Image Taken: ${re}`);
    }

    const getDate = (e) => {
        if(e === undefined || e === null){
            return "N/A"
        }
        let formattedDate = `${e.replace(/ /g, 'T')}Z`
        let nDate = new Date(formattedDate)
        let newDatee = `${getMonth(nDate.getMonth())} ${nDate.getDate()} ${nDate.getFullYear()}, ${checkLength(nDate.getHours())}:${checkLength(nDate.getMinutes())}:${checkLength(nDate.getSeconds())}`

        return newDatee
    }

    const getMonth = (monthIndex) =>{
        return monthArr[monthIndex]
    }

    const checkButtons =() => {
        if(props.imageDetails.length <= 1){
            setBtnsHiddenBool(true);
        }else{
            setBtnsHiddenBool(false);
        }
    }

    const goBackOneImage = (e) =>{
        let newId = dictionaryId - 1
        if(props.imageDetails[newId] === undefined){
            props.showAlertBox("No more images to display",true)
            return;
        }
        setDictionaryId(newId)
    }

    const goForwardOneImage = (e) =>{
        let newId = dictionaryId + 1
        if(props.imageDetails[newId] === undefined){
            props.showAlertBox("No more images to display",true)
            return;
        }
        setDictionaryId(newId)
    }

    const checkLength = (passedInTime) =>{
        if(parseInt(passedInTime) < 10){
            return `0${passedInTime}`;
        }
        return passedInTime
    }

    const loadInfo = () =>{
        return(
            <Col>
                <div key={pictures[0].ImageAge} style={{paddingLeft:"10%", paddingTop:"10px"}}>
                    <img src={latestImageUrl} hidden={imgTagHidden} width="80%" height="35%" alt={"Unable to load image"} />

                    <div>
                        <p>{imageTakenText}</p>
                        <p>Total number of images: {numberOfImages}</p>
                        <Button disabled={props.isRequestPictureDisabled} onClick={requestPicture}>Request Picture</Button>
                        <Button disabled={btnsHiddemBool}  onClick={goBackOneImage} >Forward</Button>
                        <Button disabled={btnsHiddemBool}  onClick={goForwardOneImage} >Back</Button>
                    </div>
                    <br/>
                </div>
            </Col>
        )
    }



    return(
        <Popup
            visible={props.isVisible}
            dragEnabled={false}
            hideOnOutsideClick={true}
            onHiding={props.showImage}
            showCloseButton={true}
            showTitle={true}
            title={"Image Wizard"}
            contentRender={loadInfo}
            width={"60%"}
            height={"75%"}
        >
            <Position
                at="center"
                my="center"
                of='window'
            />
        </Popup>
    )
}

const styles = {
    outer: {
        width:"100%",
        textAlign: "center"
    },
    inner: {
        display: "inline-block"
    }
}



export default CameraImages;