import React, {useEffect, useState} from 'react';
import "./Navbar.css"



function Navbar(props){
    const [homeActive,updateHomeActive] = useState("");
    const [configActive,updateConfigActive] = useState("");

    useEffect(() => {
        if(props.active === 1){
            updateHomeActive("active")
        }
        if(props.active === 2){
            updateConfigActive("active");
        }
    }, [])

    const logout = () =>{
        document.cookie = "PersonEmail=;";
        document.cookie = "PeopleTypeID=;";
        document.cookie = "CompanyID=;";
        console.log(document.cookie);
    }
    return(
        <div className="topnav">
            <a href="/"><img src="./ESGBig.png" style={{width:"40px", height:"30px"}}/></a>
            <a className={homeActive} href="/">Home</a>
            <a className={configActive} href="/Configuration">Configuration</a>
            <a href="/Login" onClick={logout}>Logout</a>
        </div>
    )
}

export default Navbar;