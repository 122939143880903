import React, { useEffect, useState } from 'react';
import DataGrid, { Column, Editing, Form } from 'devextreme-react/data-grid';
import {
    SimpleItem,
    EmailRule,
    RequiredRule,
} from 'devextreme-react/form';

function Users(props) {
    const [users, setUsers] = useState([{}]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [usersBool, updateUser] = useState(0);
    const [companyTypeDict, setCompanyTypeDict] = useState([{}]);

    const positionEditorOptions = { items: companyTypes, searchEnabled: true, value: '' }; // this is the makeup of a custom field in simple form ? not very good doc by dev express

    const styles = {
        title: {
            paddingTop: 10,
            paddingBottom: 0,
            paddingLeft: 20,
            marginBottom: 0,
            fontWeight: "bold",
        },
    }
    
    const dateBoxOptions = { width: '100%' };

    useEffect(() => {
        // get USERS
        if (props.companyID === 0) {
            return 
        }
        fetch(`https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=sec01&CompanyID=${props.companyID}`)
            .then(response => response.json())
            .then(data => {
                setUsers(data);
        });
    }, [usersBool, props.companyID]) // kinda neat how you can have two things it monitors.

    useEffect(() => {
        // get Company Types
        let url = "";
        if (props.companyID === 5500) {
            url = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=p00"
        }
        else if (props.peopleTypeID <= 4) {
            url = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=p02"
        }

        fetch(url)
            .then(response => response.json())
            .then(data => {
                
                let result = data.map(a => a.PeopleTypeName); // the place we are putting this only accepts arrays. so we map it to an array then we 
                setCompanyTypes(result);
                setCompanyTypeDict(data);
        });
    }, []) // I think b/c this is like this it only runs once. we could have this not be a function ie, no {} then it would run everytime we reredner 

    const onRowUpdated = (e) => {
        let peopleTypeID = returnPeopleIDFromPeopleLevel(e.data.PeopleTypeName);
        // for loop the stuff and get the ID and send the ID
        var urlString = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=sec03"
        urlString += "&PersonID=" + e.data.PersonID + "&PersonFirstName=" + e.data.PersonFirstName + "&PersonLastName=" + e.data.PersonLastName + "&PersonEmail=" + e.data.PersonEmail + "&PeopleTypeID=" + peopleTypeID + "&CompanyID="+props.companyID
        
        fetch(urlString)
            .then(response => response.json())
            .then(data => {
                checkPostResult(data,0);
            });
    };

    const onRowInsert = (e) =>{
        let peopleTypeID = returnPeopleIDFromPeopleLevel(e.data.PeopleTypeName);
        let urlString = "https://phpfrontendsql.azurewebsites.net/edsInHand.php?c=sec02"
        urlString += "&PersonFirstName=" + e.data.PersonFirstName + "&PersonLastName=" + e.data.PersonLastName + "&PersonEmail=" + e.data.PersonEmail + "&PeopleTypeID=" + peopleTypeID + "&CompanyID="+props.companyID

        fetch(urlString)
            .then(response => response.json())
            .then(data => {
                checkPostResult(data,1)
        });
    }

    function returnPeopleIDFromPeopleLevel(peopleLevel){
        for(let i=0; i < companyTypeDict.length; i++){
            if (companyTypeDict[i].PeopleTypeName === peopleLevel ){
                return companyTypeDict[i].PeopleTypeID
            }
        }
    }

    function checkPostResult(data, callType){
        const message = callType === 1 ? "Add" : "Updat"
        
        if (data[0].Result === 100){
            alert(`User Sucesffuly ${message}ed`)
            updateUser(previousVal => previousVal+1);
        }
        else{
            alert(`Error ${message}ing User`)
        }
    }
    
    return (
        <div>
            <div style={styles.title}>
                <h2>Users</h2>
            </div>
            <DataGrid
                dataSource={users}
                showColumnLines={false}
                showRowLines={true}
                onRowInserted={onRowInsert}
                onRowUpdated={onRowUpdated}
                wordWrapEnabled={true}
            >
                <Editing
                    allowUpdating={true}
                    allowAdding={true}
                    mode="form"
                >

                    <Form>

                        {/* Holy crap pot. If the dataField doesnt match one of the columns with same data field it doesnt show up when added or updated */}
                        <SimpleItem dataField="PersonFirstName" isRequired={true} />
                        <SimpleItem dataField="PersonLastName" isRequired={true} />
                        <SimpleItem dataField="PersonEmail" isRequired={true} >
                            <RequiredRule message="Email is required" />
                            <EmailRule message="Invalid Email"/>
                        </SimpleItem>
                        {/* <SimpleItem dataField="PeopleTypeID" isRequired={true} /> */}
                        <SimpleItem dataField="PeopleTypeName" editorType="dxSelectBox" editorOptions={dateBoxOptions} allowEditing={false}  editorOptions={positionEditorOptions} isRequired={true}/>
                    </Form>
                </Editing>

                <Column dataField="CompanyName" caption="Company" alignment="center" allowEditing={false}/>
                <Column dataField="PersonFirstName" caption="First Name" alignment="center" />
                <Column dataField="PersonLastName" caption="Last Name" alignment="center" />
                <Column dataField="PersonEmail" caption="Email" alignment="center" />
                <Column dataField="PeopleTypeName" caption="Type" alignment="center" />
                <Column dataField="PeopleTypeID" caption="Role" alignment="center" visible={false}/>
                <Column dataField="Validated" caption={"Verified"} alignment="center" allowEditing={false} dataType="boolean"/>
                <Column dataField="InsertedDT.date" caption="Created Date" alignment="left" allowEditing={false} dataType="datetime" />

            </DataGrid>

        </div>
    )
}

export default Users;