import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import Home from './Home/Home'
/*import 'bootstrap/dist/css/bootstrap.min.css';*/
import Configuration from './Configuration/Configuration';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Main from '../Main'

function App() {
    const rootDirectory = "/Default";
    return (
        <Main rootDirectory={rootDirectory} />
    );
}

export default App;